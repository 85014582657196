import { HeavyDogApiClientExtended } from '@/repositories';

export interface IContainer {
    apiClient: HeavyDogApiClientExtended;
}

export default class Container implements IContainer {
    get apiClient(): HeavyDogApiClientExtended {
        return new HeavyDogApiClientExtended();
    }
}

export { Container };
